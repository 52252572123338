<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'home-agentscout' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Home
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
        
    <div class="text-center my-3" v-if="Caricato == false">
      <b-spinner  v-if="errore_caricamento == false"
          variant="primary"
          style="width: 3rem; height: 3rem;"
          label="loading"
      />
      <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
    </div>

    <b-card bg-variant="Default" v-if="Caricato">
      <b-row class="match-height">
        <b-col
          md="11"
          cols="10" 
          class="mb-2"
        >
          <!-- input search -->
          <b-input-group>
            <b-input-group-prepend>
              <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca.sezione" :options="options_ricerca" disabled-field="notEnabled"></b-form-select>
            </b-input-group-prepend>
              <b-form-input v-model="campiRicerca.nome"
                  placeholder="ricerca generale"
                  type="text" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click.prevent="ricercaGenerale">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>

          </b-input-group>
        </b-col>
        <b-col
          md="1"
          cols="2"
          class="mb-2"
        >
          <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset" block v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
        </b-col>
        
      </b-row>

        <!-- table -->
        <vue-good-table
        mode="remote"
        ref="table"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :rows="rows"
        :columns="columns"
        :isLoading=isLoading
        :search-options="{ 
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        
        <template slot="loadingContent">
          <div class="text-center my-3">
              <b-spinner
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
              />
              <div class="d-block pt-1 text-primary">Caricamento</div>
          </div>
        </template>
        
        <div slot="emptystate"> <div class="text-center py-4"> Nessun dato disponibile - (Messaggio personalizzabile) </div> </div>

        <template
            slot="table-row"
            slot-scope="props"
        >
            
            <!-- Column: azioni -->
            <span v-if="props.column.field === 'azioni'">
              <div class="text-nowrap">
                <feather-icon
                  icon="ArrowRightIcon"
                  class="cursor-pointer text-primary"
                  size="21"
                  v-b-tooltip.hover.v-primary
                  title="Azioni"
                  :id="`tabellatemplate-riga-${props.row.id}-sidebar-azioni`"
                  @click="sidebarAction(props.row.id, props.row.nome, props.row.descrizione )"
                />
              </div>
            </span>

            <!-- Column: updated_at -->
            <div v-else-if="props.column.field === 'updated_at'">
              {{ formattazioneDataOra(props.row.updated_at, 'it') }}
            </div>

            <!-- Column: standard -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Visualizzazione da 1 a 
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['10','25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> di {{ props.total }} righe </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
            
    </b-card>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar-right"
      bg-variant="white"
      :title="infoSideBar.titolo"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          
          <div class="divider">
              <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
          </div>
          <b-row cols="1">
            <b-col class="riga_sidebar">
              <span class="font-weight-bold">Descrizione:</span> {{ infoSideBar.descrizione }}
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="$router.push({ name: 'tools-contracttemplate-show', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="EyeIcon" /> Visualizza
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'tools-contracttemplate-edit', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="Edit2Icon" /> Modifica
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'tools-contracttemplate-duplic', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="CopyIcon" /> Duplica
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger" block
            class="mb-1"
            @click="cancellaRiga(infoSideBar.id)"
          >
            <feather-icon icon="Trash2Icon" /> Cancella
          </b-button>
        </div>

      </div>
    </b-sidebar>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true, 
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Nome template',
          field: 'nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Aggiornato al',
          field: 'updated_at',
          width: '140px',
          filterOptions: {
            enabled: false,
            placeholder: 'Filtra',
          },
        },

        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      resetState: 'hidden',
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
            {
            field: 'nome',
            type: 'asc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams: {
        columnFilters: {},
        sort: [
            {
            field: 'nome',
            type: 'asc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca: [
        { value: null, text: 'seleziona...' },
        { value: 'nome', text: 'Nome template' },
        { value: 'descrizione', text: 'Descrizione template' },
        { value: 'id_prod_stripe', text: 'Codice prodotto Stripe (es. prod_abc123...)' },
      ],
      campiRicerca: {
        nome: '',
        sezione: null,
      },
      infoSideBar: {
        id: '',
        fullName: '',
        email: '',
        phone: '',
        lista_licenze: [ ],
      },
      Caricato: false,
      errore_caricamento: false,
      windowWidth: 0,
    }
  },
  computed: {
  },
  mounted() {
    //in caso di resize del browser -> ricalcolo della larghezza della finestra
    window.onresize = () => {
      this.checkWindowsSizeAndResponsiveGoodTable();
    }
  },
  created() {
    this.checkWindowsSizeAndResponsiveGoodTable();
    
    //adesso faccio il caricamento dei dati
    this.loadItems();

    this.Caricato = true;
    this.errore_caricamento = false;
  },
  methods: {
    checkWindowsSizeAndResponsiveGoodTable(){

      if(window.innerWidth <1000){
        this.$set(this.columns[0], 'hidden', false)
        this.$set(this.columns[1], 'hidden', true)
      } else {
        //tutte le colonne visualizzate
        this.$set(this.columns[0], 'hidden', false)
        this.$set(this.columns[1], 'hidden', false)
      }
    },
    formattazioneDataOra(converted_at,lang) {
      if (converted_at) {
        return moment(String(converted_at)).locale(lang).format('DD/MM/YY - HH:mm')
      } else {
        return '---'
      }
    },
    ricercaGenerale(){
      console.log("ricerca generale - chiamata Api per avere la ricerca su tutti i dati e NON solo sui dati dell'attuale viewport della tabella ;-)")
      console.log(this.campiRicerca)

      if((this.campiRicerca.sezione !== null) && (this.campiRicerca.sezione !== 'undefined')){
        console.log("ok ricerca valida")

        //aggiorna parametri con la stessa funzione del filtro colonna ;-)
        this.updateParams({
          columnFilters: {
            [this.campiRicerca.sezione]: this.campiRicerca.nome
          },
        });

        //forza reset filtro colonne
        this.$refs.table.reset();

        this.loadItems();

        //attiva bottone "reset"
        this.resetState = 'attiva';
      }
    },
    clickReset(){
      console.log("clickReset");

      //forza reset filtro colonne
      this.$refs.table.reset();

      //forza reset parametri iniziali della tabella
      this.updateParams(this.startServerParams);
      this.loadItems();

      //reset campi ricerca
      this.campiRicerca.nome = '';
      this.campiRicerca.sezione = null;
      //disattiva bottone "reset"
      this.resetState = 'hidden';
    },
    advanceFilter(val) {
      //this.searchTerm = val

      this.updateParams(val);
      this.loadItems();
    },
    sidebarAction(id, nome, descrizione) {
      this.infoSideBar = { id: id,
        titolo: nome,
        descrizione: descrizione };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    
    updateParams(newProps) {
      //console.log(newProps)

      //attiva bottone "reset" filtro o ricerca
      this.resetState = 'attiva';

      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      console.log('cambio ordinamento')
      console.log(params)

      let field_ricerca = '';

      this.updateParams({
        sort: [{
          type: params[0].type,
          field: field_ricerca,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    loadItems() {
      this.isLoading = true;

      this.$http.get('api/crm/contracttemplate/list', {
        params: this.serverParams 
        }).then(response => { 
            if(response.data.statusCode == 200){
              //ok
              this.totalRecords = response.data.reply.meta.total;
              this.rows = response.data.reply.data;
              
            } else if(response.data.statusCode == 422){
              //errori con testo di notifica ok dall'api
              this.$swal({
                title: 'Errore',
                text: 'codice errore: '+response.data.reply,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            } else {
              //tutto gli altri possibili errori
              this.$swal({
                title: 'Errore Generico',
                text: 'codice errore: '+response.data.statusCode,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            }
            
            //rimuovi il loading dopo il caricamento dei dati nella tabella
            this.isLoading = false;
        
        })

    },
    cancellaRiga(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del contratto template?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/contracttemplate/delete/'+id_riga)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.reply,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems();
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.riga_sidebar{
  padding-bottom: 5px;
}
</style>